@mixin agdir-table-style {
	@apply w-full border-collapse rounded-1;

	thead {
		@apply sticky top-0;
		//@apply bg-blue-500/[.3];
		background-color: #eef3ff;
		//&.sticky {
		//	@apply top-0;
		//}
		tr {
			th:first-child {
				@apply rounded-tl-[5px];
			}

			th:last-child {
				@apply rounded-tr-[5px];
			}
		}
	}

	tbody {
		tr {
			@apply h-4 hover:bg-blue-100 border-gray-400 border-b;
			&.cursor-pointer {
				@apply hover:bg-blue-100;
			}
		}
	}

	th,
	td {
		@apply text-left py-1 px-2 text-[12px] lg:text-[16px];
	}
}

table.agdir-table-ui {
	@include agdir-table-style();
}

table.agdir-table {
	@include agdir-table-style();

	th,
	td {
		@apply text-left py-1 px-2 text-[16px] lg:text-[12px];
		&:nth-child(1),
		&:nth-child(2) {
			width: 190px;
		}

		&:not(:nth-child(1), :nth-child(2)) {
			text-align: right;
		}
	}
}
