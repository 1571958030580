.agdir-h-screen-safe {
	height: 100vh;
}

.agdir-min-h-screen-safe {
	min-height: 100vh;
}

//TODO: remove me, when iOS will fully support 100vh
@supports (-webkit-touch-callout: none) {
	.agdir-h-screen-safe {
		// height: 100dvh;
		height: -webkit-fill-available;
	}
	.agdir-min-h-screen-safe {
		min-height: -webkit-fill-available;
	}
}
