.ant-btn-primary {
	background-color: var(--primary-button-bg);
	border-color: var(--primary-button-bg);
}

@mixin agdir-border-style {
	border-radius: 5px !important;
	border-color: #d9d9d9 !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
	outline: 1px solid red;
	background-color: #fbeaea;
}

.ant-popover-inner {
	border-radius: 10px;
}

.agdir-ant-popover-inner-content-zero-padding .ant-popover-inner-content {
	padding: 0;
}

.ant-modal-content {
	border-radius: 10px !important;
	box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.08);
}

.ant-input-affix-wrapper,
.ant-cascader .ant-select-selector,
.ant-input-number {
	@include agdir-border-style;
}

.ant-input-affix-wrapper {
	padding: 0px 8px;
}

.ant-picker:not(.ant-picker-inline),
.ant-input,
.ant-input-number-input,
.ant-select-selector,
.ant-select-selection-search-input,
.ant-select nz-select-top-control {
	@include agdir-border-style;
	padding: 6px 11px !important;
	min-height: 36px !important; // removed height or else autoHeight on textarea does not work
}

.ant-modal-mask {
	opacity: 0.5 !important;
	background: #d9d9d9 !important;
}

.ant-select-selection-placeholder,
nz-select-item.ant-select-selection-item,
nz-cascader .ant-select-selection-item {
	line-height: inherit !important;
}

nz-input-group {
	padding-left: 0 !important;

	.ant-input {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		padding: 4px 11px;

		& + * + .ant-input-group-addon {
			border-top-left-radius: 5px !important;
			border-bottom-left-radius: 5px !important;
		}

		& + .ant-input-group-addon {
			border-top-right-radius: 5px !important;
			border-bottom-right-radius: 5px !important;
		}
	}

	.ant-input-number {
		.ant-input-number-input {
			padding-right: 0 !important;
			// text-align: right;
		}

		.ant-input-number-handler-wrap {
			display: none !important;
		}

		box-shadow: none !important;
	}

	.ant-input-number,
	nz-select-top-control.ant-select-selector {
		border: none !important;
	}
}

.ant-form {
	font-size: inherit;
}

.ant-form-item {
	margin-bottom: 16px;

	.ant-form-item-label {
		padding-bottom: 4px !important;

		& > label {
			height: auto !important;
			//font-weight: bold;
		}
	}

	.ant-form-item-control {
		width: 100%;
	}

	.ant-input-number {
		border-radius: 5px;
	}
}

// caledndar start
.ant-picker-cell .ant-picker-cell-inner {
	height: 32px;
	padding: 6px;
	line-height: 1.2em;
}

.ant-picker-dropdown {
	font-size: 16px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background-color: var(--secondary-button-bg);
	border-radius: 30px;
	font-weight: bold;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-radius: 30px;
	border-color: var(--secondary-button-bg);
}

[nzinline] .ant-picker-panel-container {
	box-shadow: none !important;
}

.ant-picker-cell-in-view {
	color: inherit !important;
}

.ant-picker-week-panel-row,
.ant-picker-week-panel-row {
	td:first-child {
		color: black;
		background-color: white;
		font-weight: bold;
	}
}

.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	//color: black;
	min-width: 2em;

	div {
		min-width: 2em;
	}
}

.ant-picker-week-panel-row-selected,
.ant-picker-week-panel-row-selected {
	& td,
	&:hover td {
		color: var(--secondary-button-text);

		div {
			color: var(--secondary-button-text) !important;
		}

		background-color: var(--secondary-button-bg);

		&:first-child {
			color: black;
			background-color: white;
			font-weight: bold;
		}

		&:nth-child(2) {
			div {
				color: white !important;
			}

			background-color: var(--secondary-button-bg);
			border-radius: 30px 0 0 30px;
		}

		&:last-child {
			div {
				color: white !important;
			}

			background-color: var(--secondary-button-bg);
			border-radius: 0 30px 30px 0;
		}
	}
}

// caledndar end

.ant-checkbox-wrapper,
.ant-input-affix-wrapper {
	font-size: inherit;
}

// https://agdiras.monday.com/boards/1649864138/pulses/1660119050
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	background-color: var(--checkbox-bg);
	border-color: var(--checkbox-bg);
}

.ant-radio-inner {
	border-color: var(--radio-bg) !important;

	&::after {
		background-color: var(--radio-bg);
	}
}

.ant-tooltip-content {
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.ant-tooltip-arrow {
	.ant-tooltip-arrow-content {
		background-color: var(--tooltip-bg);

		&::before {
			background: var(--tooltip-bg);
		}
	}
}

.ant-tooltip-inner {
	background-color: var(--tooltip-bg);
	color: var(--tooltip-text);
	border-radius: 2px;
}

.ant-picker.ant-picker-borderless {
	padding: 0;
	border: none;

	input {
		line-height: 1;
		font-size: 16px;
		font-family: GTHaptikRegular, sans-serif;
	}

	.ant-picker-clear,
	.ant-picker-suffix {
		display: none;
	}
}

nz-option-container.ant-select-dropdown {
	border-radius: 8px;
}

nz-option-item.ant-select-item {
	&.ant-select-item-option-selected {
		font-weight: normal;
		background-color: var(--dropdown-selected-bg);
	}
}

nz-time-picker-panel.ant-picker-time-panel .ant-picker-content {
	ul li.ant-picker-time-panel-cell {
		&-selected > div {
			background-color: var(--body-bg);
		}
	}
}

nz-switch {
	button.ant-switch-checked {
		background-color: var(--switch-bg);
	}
}

// dropdown
.ant-dropdown-menu {
	border-radius: 10px;
}
