body:not([data-theme]),
[data-theme='blue'],
[data-theme='default'],
.agdir-theme-blue {
	--body-bg: #f4f5f7;
	--primary-button-bg: #473ae4;
	--primary-button-text: #ffffff;
	--primary-button-hover-bg: #17095b;
	--primary-button-active-bg: #2d1a8a;
	--primary-button-disabled-bg: #938aef;
	--secondary-button-bg: #e2f1ff;
	--secondary-button-text: #000000;
	--secondary-button-border: #d1e0ff;
	--secondary-button-hover-bg: #d1e0ff;
	--secondary-button-active-bg: #c6d8ff;
	--secondary-button-disabled-bg: #fafcff;
	--secondary-button-disabled-text: #61646b;
	--secondary-button-disabled-border: #d1e0ff;

	--outline-button-bg: #ffffff;
	--outline-button-text: #000;
	--outline-button-border: #c3c6cd;
	--outline-button-hover-bg: #f1f1f1;
	--outline-button-active-bg: #f1f1f1;
	--outline-button-disabled-bg: #f1f1f1;
	--outline-button-disabled-text: #61646b;
	--outline-button-disabled-border: #c3c6cd;

	--tab-toggle-bg: #e2e5ee;
	--tab-toggle-text: #81899f;
	--tab-toggle-active-bg: #ffffff;
	--tab-toggle-active-text: #473ae4;

	--tooltip-bg: #090735;
	--tooltip-text: #ffffff;

	--radio-bg: #473ae4;
	--switch-bg: #473ae4;
	--checkbox-bg: #473ae4;

	--dropdown-selected-bg: #f1f1f1;
}
