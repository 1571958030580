[data-theme='green'],
.agdir-theme-green {
	--body-bg: #eff8ec;
	--primary-button-bg: #9ad58b;
	--primary-button-text: #000000;
	--primary-button-hover-bg: #9ad58b;
	--primary-button-active-bg: #9ad58b;
	--primary-button-disabled-bg: #e2f3dd;
	--secondary-button-bg: #0e3810;
	--secondary-button-text: #ffffff;
	--secondary-button-border: #0e3810;
	--secondary-button-hover-bg: #356d27;
	--secondary-button-active-bg: #356d27;
	--secondary-button-disabled-bg: #e2f3dd;
	--secondary-button-disabled-text: #254b1b;
	--secondary-button-disabled-border: #e2f3dd;

	--outline-button-bg: transparent;
	--outline-button-text: #000;
	--outline-button-border: #0e3810;
	--outline-button-hover-bg: #eff8ec;
	--outline-button-active-bg: #eff8ec;
	--outline-button-disabled-bg: #eff8ec;
	--outline-button-disabled-text: #484c56;
	--outline-button-disabled-border: #0e3810;

	--tab-toggle-bg: #f4f5f7;
	--tab-toggle-text: #484c56;
	--tab-toggle-active-bg: #0e3810;
	--tab-toggle-active-text: #ffffff;

	--tooltip-bg: #0e3810;
	--tooltip-text: #ffffff;

	--checkbox-bg: #0e3810;
	--radio-bg: #0e3810;
	--switch-bg: #0e3810;

	--dropdown-selected-bg: #eff8ec;

	--journal-drop-container-bg: #eff8ec;
	--journal-drop-container-border: #9ad58b;
}
