@font-face {
	font-family: GTHaptikRegular;
	src: url(/assets/fonts/GT-Haptik-Regular-2.otf);
	font-weight: normal;
}

@font-face {
	font-family: GTHaptikRegular;
	src: url(/assets/fonts/GT-Haptik-Bold-2.otf);
	font-weight: bold;
}

.figma-header1 {
	font-size: 32px;
	line-height: 40px;
}

.figma-header2 {
	font-size: 24px;
	line-height: 30px;
}

.figma-header3 {
	font-size: 20px;
	line-height: 25px;
}

.figma-sub-header {
	font-size: 20px;
	line-height: 25px;
}

.figma-mobile-header {
	font-size: 18px;
	line-height: 22px;
}

.figma-body-text-small-regular {
	font-size: 12px;
	line-height: 1.45;
}

.figma-body-text-regular {
	font-size: 16px;
	line-height: 1.45;
}
