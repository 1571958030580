pre {
	border: 1px solid red;
	background-color: #333333;
	color: #aaaaaa;
	padding: 1em;
	border-radius: 1em;
	font-size: 0.8em !important;
}

.flex-row-center {
	@apply flex flex-row items-center justify-center;
}

hr {
	@apply w-full bg-gray-200 h-[1px];
}

.bg-theme {
	background-color: var(--body-bg);
}
