@use 'styles/fonts' as *;
@use 'styles/ant' as *;
@use 'styles/screen-polyfill' as *;
@use 'styles/formly-overrides' as *;
@use 'styles/material' as *;
@use 'styles/mapbox' as *;
@use 'styles/agdir-nice-things' as *;
@use 'styles/themes/blue' as *;
@use 'styles/themes/green' as *;
@use 'styles/tables' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		text-rendering: auto;
		-webkit-tap-highlight-color: transparent;
		font-smooth: antialiased;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* Remove the focus ring */
		&:focus {
			outline: none !important;
		}
	}

	:root {
		--agColumnWidth: 64px;
		--scrollbar-border-radius: 10px;
		interpolate-size: allow-keywords;
	}
}

html {
	line-height: normal;
}

body {
	background-color: var(--body-bg);
	font-family: GTHaptikRegular, sans-serif;
	font-feature-settings: inherit;
	@apply text-[12px] md:text-[16px];
	font-variant: normal;
}

// Router outlet is taking up a space in grid
router-outlet {
	display: none;
}
