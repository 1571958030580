@import "ng-zorro-antd/ng-zorro-antd.less";

@primary-color: #8bb8f8;


nz-select.ant-select {
	font-family: GTHaptikRegular, sans-serif;
	font-feature-settings: inherit;
	font-variant: normal;
}

.ant-select-dropdown {
	font-feature-settings: inherit;
	font-variant: normal;
}
